body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #252535;
  color: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* My stuff */
$phone: 500px;
$highlight: #ffca58;
$rowHighlight: #686868;
$pickedColor: #181818;

.hidden {
  display: none;
}

#login-email {
  width:230px;
  height: 30px;
  font-size: 18px;
}

#login-submit {
  height: 35px;
  font-size: 18px;
}

.active-user {
  @media screen and (max-width: $phone) {
    display: none;
  }
}

a, a:visited {
  color: #eee;
}

h3 {
  margin-top: 50px;
}

.league-list {
  font-size: 20px;
}

.pick-grid {
  border-collapse: separate;
  margin-bottom: 20px;
  background-color: #282828;

  td {
    border: 1px solid black;
    font-weight: bold;
  }

  tbody tr:hover td {
    border-top-color: $rowHighlight;
    border-bottom-color: $rowHighlight;

    &.current-week {
      border-color: yellow;
    }
  }

  th {
    border: 1px solid black;
  }

  td, th {
    font-size: 18px;
    min-width:40px;
    text-align: center;
    padding:1px;

    &.default-cell {
      background-color: #333;
    }

    &.sticky {
      position: sticky;
      left: 0;
      z-index: 2;
    }

    &.current-week {
      border: 2px dashed yellow;
    }

    &.is-active-user {
      color: $highlight;
    }

    &.player-name {
      padding-left:5px;
      text-align: inherit;
      background-color: #222;
      min-width:130px;
    }
  }

  td {
    &.player-name {
      font-weight: normal;
    }
  }

  .player-last {
    font-weight: normal;
    font-size: 14px;
  }
}

.pick-archive {
  padding-bottom:40px;
}

.__react_component_tooltip::before {
  display:none;
}

table.week-picks {
  td.player-name {
    font-weight: normal;
  }
  td {
    font-weight: bold;
  }
}

.resources a {
  margin-right: 10px;
}

.team-picker {
  margin-right: 10px;
  height: 40px;
  font-size: 18px;
}

.pick-submit {
  height: 40px;
  font-size: 18px;
}

input#show-picked {
  margin-top: 10px;
}

.form-status, .warning {
  color: $highlight;
}

.current-pick {
  font-weight: bold;
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #aaa;
  border-radius: 3px;
}

.content {
  padding-left: 20px;
  padding-right: 20px;
}

.grid-wrapper {
  overflow-x: auto;
}

.pick-grid .player-byes {
  background-color: $pickedColor;
  color: white;
}

td.player-byes {
  font-size: 12px;
}


.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: black;
  padding: 20px;
  display: none;
  color: white;
  border-radius: 10px;
}

.modal-active {
  display: block;
}

.modal h3 {
  margin-top: 0;
}

form label {
  margin-right: 10px;
}

.account-panel {
  float: right;
  height: inherit;
  display: flex;
  align-items: center;
}

.account-panel {
  a, span {
    margin-right: 20px;
  }
}

.global-header {
  height: 50px;
  background: black;
  color: white;
}

.global-header a {
  cursor: pointer;
}

form input:not(:first-of-type) {
  margin-left: 10px;
}

.logo {
  display: flex;
  height: inherit;
  margin: 0;
  margin-left: 10px;
  float: left;
  align-items: center;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.modal form input[type=text] {
  height: 30px;
  width: 200px;
  font-size: 18px;
}

.histogram {
  background: #222;
  width: 600px;
  border: 1px solid black;
  margin-bottom: 20px;
  max-width: 100%;
  overflow: auto;

  text {
    fill: #ddd;
    font-size:14px;
  }

  line {
    stroke: #ddd;
  }

  rect {
    fill: cyan;
    opacity: 1;
    stroke: black;
    stroke-width: 2;
  }

  path {
    stroke: magenta;
  }
}


td.outcome-double-win {
  background-color: $pickedColor;
  color: #018d08;
}

td.outcome-double-loss {
  background-color: $pickedColor;
  color: #a3e97a;
}

td.outcome-unknown {
  background-color: $pickedColor;
  color: white;
  font-weight: bold;
}

td.outcome-split {
  background-color: $pickedColor;
  color: #eb1717;
}

td.gameresult {
  &::after {
    display:inline-block;
    font-size: 0.8rem;
    position: absolute;
    bottom: 0px;
    width: 16px;
    text-align: center;
    text-shadow: 0px 0px 1px black;
  }
}

td.gameresult-win {
  position: relative;
  &::after {
    content: '✓';
  }
}

td.gameresult-loss {
  position: relative;
  &::after {
    content: '✗';
  }
}

td.gameresult-left {
  text-align: left;
  padding-left: 5px;
  &::after {
    right: 0px;
  }
}

td.gameresult-right {
  text-align: left;
  padding-left: 5px;
  &::after {
    right: 0px;
  }
}

td.gameresult-tie {
  background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%);
  color: white;
}

td.gameresult-unknown {

}

.team-ari {
  background-color: #97233F;
  color: #FFB612;
}

.team-atl {
  background-color: #A71930;
  color: white;
}

.team-bal {
  background-color: #241773;
  color: white;
}

.team-buf {
  background-color: #00338D;
  color: #C60C30;
}

.team-car {
  background-color: #0085CA;
  color: black;
}

.team-chi {
  background-color: #0B162A;
  color: #C83803;
}

.team-cin {
  background-color: #FB4F14;
  color: black;
}

.team-cle {
  background-color: #311D00;
  color: #FF3C00;
}

.team-dal, th.team-dal {
  background-color: #869397;
  color: #041E42;
}

.team-den {
  background-color: #FB4F14;
  color: #002244;
}

.team-det {
  background-color: #0076B6;
  color: white;
}

.team-gb {
  background-color: #203731;
  color: #FFB612;
}

.team-hou {
  background-color: #03202F;
  color: #A71930;
}

.team-ind {
  background-color: #002C5F;
  color: white;
}

.team-jax {
  background-color: #006778;
  color: #D7A22A;
}

.team-kc {
  background-color: #E31837;
  color: #FFB81C;
}

.team-lac {
  background-color: #0080C6;
  color: #FFC20E;
}

.team-lar {
  background-color: #003594;
  color: #FFA300;
}

.team-lv {
  background-color: black;
  color: #A5ACAF;
}

.team-mia {
  background-color: #008E97;
  color:  white;
}

.team-min {
  background-color: #4F2683;
  color: #FFC62F;
}

.team-ne {
  background-color: #002244;
  color: #B0B7BC;
}

.team-no {
  background-color: #D3BC8D;
  color: #101820;
}

.team-nyg {
  background-color: #0B2265;
  color: white;
}

.team-nyj {
  background-color: #125740;
  color: white;
}

.team-phi {
  background-color: #004C54;
  color: #ACC0C6;
}

.team-pit {
  background-color: #FFB612;
  color: #101820;
}

.team-sea {
  background-color: #002244;
  color: #69BE28;
}

.team-sf {
  background-color: #B3995D;
  color: #AA0000;
}

.team-tb {
  background-color: #D50A0A;
  color: #FF7900;
}

.team-ten {
  background-color: #4B92DB;
  color: #0C2340;
}

.team-was {
  background-color: #773141;
  color: #FFB612;
}
